import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../../helpers/ErrorHandler";

class CategoryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { categorys: [] };
  }

  componentDidMount() {
    this.props.api
      .get(`qa`)
      .then((response) => {
        this.setState({ categorys: response.data });
      })
      .catch((error) => {
        ErrorHandler(error, this.props);
        this.props.updateUsername(null);
        this.props.history.push("/login");
      });
  }

  downloadPdf = (e) => {
    this.props.api
      .get(`qa/download-pdf`, { responseType: "blob" })
      .then((response) => {
        var data = new Blob([response.data], { type: response.headers["content-type"] });
        var pdfURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement("a");
        tempLink.href = pdfURL;
        tempLink.setAttribute("download", "QA.pdf");
        tempLink.click();
      })
      .catch((error) => {
        ErrorHandler(error, this.props);
        this.props.updateUsername(null);
        this.props.history.push("/login");
      });
  };

  render() {
    const renderCategoryList = this.state.categorys.map((category) => {
      return (
        <div className="pb-2" key={category.id.S}>
          <Link className="text-decoration-none fw-bold" to={{ pathname: `/qa/${category.category.S}` }}>
            {category.category.S}
          </Link>
        </div>
      );
    });

    return (
      <div className="pt-4 m-1 border rounded text-center bg-white">
        <div className="mb-3">
          <Link className="text-decoration-none" onClick={this.downloadPdf} to="#">
            <FontAwesomeIcon icon={faFilePdf} size="2x" />
          </Link>
        </div>
        <h3>Category</h3>
        <div className="my-3">{renderCategoryList}</div>
      </div>
    );
  }
}

export default CategoryList;
