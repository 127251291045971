import React from "react";
import ErrorHandler from "../../helpers/ErrorHandler";
import * as Config from "../../helpers/Config";
import urlExist from "url-exist";

class PhotoEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = { newPhoto: null, currentPhoto: null, uploadButtonDisabled: true, currentPhotoHtml: null };
  }

  componentDidMount() {
    this.showPhoto();
  }

  onFileChange = (event) => {
    if (event.target.files[0]) {
      this.setState({ newPhoto: event.target.files[0] });

      const allowedTypes = ["image/jpeg"];
      if (allowedTypes.indexOf(event.target.files[0].type) === -1) {
        this.props.setNotificationMessage({ class: "danger", message: "Please upload jpeg file." });
        this.setState({ uploadButtonDisabled: true });
        return;
      }

      this.setState({ uploadButtonDisabled: false });
    } else {
      this.setState({ uploadButtonDisabled: true });
    }
  };

  // On file upload (click the upload button)
  onFileUpload = () => {
    if (this.state.newPhoto) {
      const formData = new FormData();
      formData.append("photo", this.state.newPhoto, this.state.newPhoto.name);

      this.props.api
        .post(`photo_editor/upload`, formData)
        .then(() => {
          this.props.setNotificationMessage({ class: "success", message: "Uploaded Successfully" });
          this.showPhoto();
          return;
        })
        .catch((error) => {
          ErrorHandler(error, this.props);
          this.props.history.push("/login");
        });
    }
  };

  fileData = () => {
    if (this.state.newPhoto) {
      return (
        <div className="mt-3">
          <p className="m-0">File Name: {this.state.newPhoto.name}</p>
          <p className="m-0">File Type: {this.state.newPhoto.type}</p>
          <p className="m-0">Last Modified: {this.state.newPhoto.lastModifiedDate.toDateString()}</p>
        </div>
      );
    } else {
      return <div className="mt-3">Choose a file to see details.</div>;
    }
  };

  showPhoto = () => {
    this.setState({ currentPhoto: null });
    (async () => {
      const photoUrl = Config.API_URL + "/tmp/photo.jpeg";
      const result = await urlExist(photoUrl);
      if (result) this.setState({ currentPhoto: photoUrl });
    })();
  };

  enlargeImage = () => {};

  render() {
    return (
      <div className="p-3 m-1 border rounded bg-white">
        <div>
          <div className="text-center">
            <h3>Photo Editor</h3>
          </div>
          <div className="text-center mt-5">
            <input className="form-control w-50 m-auto" type="file" onChange={this.onFileChange} />
            <button
              className="btn btn-primary mt-2"
              onClick={() => {
                this.onFileUpload();
              }}
              disabled={this.state.uploadButtonDisabled}
            >
              Upload
            </button>
            {this.fileData()}
            <div className={(this.state.currentPhoto ? "" : "d-none") + " mt-3 border-top"}>
              <div className="mt-4 p-2 border w-25 m-auto">
                <span className="me-5">Current photo</span>
                <img className="col-3 border" src={this.state.currentPhoto} alt="Not found" />
              </div>
              <div>
                <button
                  className="btn btn-primary mt-2"
                  onClick={() => {
                    this.enlargeImage();
                  }}
                >
                  Enlarge
                </button>
              </div>
              <div className="p-2 mt-3 border rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PhotoEditor;
