import React from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";

const MenuBar = ({ editor }) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="border-bottom rounded p-1 btn-toolbar">
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleBold().run();
        }}
        className={(editor.isActive("bold") ? "btn-dark" : "btn-secondary") + " btn btn-secondary me-1 m-1 btn-sm"}
      >
        bold
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleItalic().run();
        }}
        className={(editor.isActive("italic") ? "btn-dark" : "btn-secondary") + " btn btn-secondary me-1 m-1 btn-sm"}
      >
        italic
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleStrike().run();
        }}
        className={(editor.isActive("strike") ? "btn-dark" : "btn-secondary") + " btn btn-secondary me-1 m-1 btn-sm"}
      >
        strike
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleCode().run();
        }}
        className={(editor.isActive("code") ? "btn-dark" : "btn-secondary") + " btn btn-secondary me-1 m-1 btn-sm"}
      >
        code
      </button>
      {/* <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().unsetAllMarks().run();
        }}
        className=" btn btn-secondary me-1 m-1 btn-sm"
      >
        clear marks
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().clearNodes().run();
        }}
        className=" btn btn-secondary me-1 m-1 btn-sm"
      >
        clear nodes
      </button> */}
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().setParagraph().run();
        }}
        className={(editor.isActive("paragraph") ? "btn-dark" : "btn-secondary") + " btn me-1 m-1 btn-sm"}
      >
        paragraph
      </button>
      {/* <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleHeading({ level: 1 }).run();
        }}
        className={
          (editor.isActive("heading", { level: 1 }) ? "btn-dark" : "btn-secondary") +
          " btn btn-secondary me-1 m-1 btn-sm"
        }
      >
        h1
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleHeading({ level: 2 }).run();
        }}
        className={
          (editor.isActive("heading", { level: 2 }) ? "btn-dark" : "btn-secondary") +
          " btn btn-secondary me-1 m-1 btn-sm"
        }
      >
        h2
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleHeading({ level: 3 }).run();
        }}
        className={
          (editor.isActive("heading", { level: 3 }) ? "btn-dark" : "btn-secondary") +
          " btn btn-secondary me-1 m-1 btn-sm"
        }
      >
        h3
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleHeading({ level: 4 }).run();
        }}
        className={
          (editor.isActive("heading", { level: 4 }) ? "btn-dark" : "btn-secondary") +
          " btn btn-secondary me-1 m-1 btn-sm"
        }
      >
        h4
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleHeading({ level: 5 }).run();
        }}
        className={
          (editor.isActive("heading", { level: 5 }) ? "btn-dark" : "btn-secondary") +
          " btn btn-secondary me-1 m-1 btn-sm"
        }
      >
        h5
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleHeading({ level: 6 }).run();
        }}
        className={
          (editor.isActive("heading", { level: 6 }) ? "btn-dark" : "btn-secondary") +
          " btn btn-secondary me-1 m-1 btn-sm"
        }
      >
        h6
      </button> */}
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleBulletList().run();
        }}
        className={
          (editor.isActive("bulletList") ? "btn-dark" : "btn-secondary") + " btn btn-secondary me-1 m-1 btn-sm"
        }
      >
        bullet list
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleOrderedList().run();
        }}
        className={
          (editor.isActive("orderedList") ? "btn-dark" : "btn-secondary") + " btn btn-secondary me-1 m-1 btn-sm"
        }
      >
        ordered list
      </button>
      {/* <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleCodeBlock().run();
        }}
        className={(editor.isActive("codeBlock") ? "btn-dark" : "btn-secondary") + " btn btn-secondary me-1 m-1 btn-sm"}
      >
        code block
      </button>
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().toggleBlockquote().run();
        }}
        className={
          (editor.isActive("blockquote") ? "btn-dark" : "btn-secondary") + " btn btn-secondary me-1 m-1 btn-sm"
        }
      >
        blockquote
      </button> */}
      <button
        onClick={(e) => {
          e.preventDefault();
          editor.chain().focus().setHorizontalRule().run();
        }}
        className=" btn btn-secondary me-1 m-1 btn-sm"
      >
        horizontal rule
      </button>
    </div>
  );
};

const TipTap = ({ updateAnswer, content = "" }) => {
  const editor = useEditor({
    extensions: [StarterKit],
    content: content,
    onUpdate({ editor }) {
      const html = editor.getHTML();
      updateAnswer(html);
    },
  });

  return (
    <div className="border rounded">
      <MenuBar editor={editor} />
      <EditorContent editor={editor} className="p-2" />
    </div>
  );
};

export default TipTap;
