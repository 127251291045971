import React from "react";
import ErrorHandler from "../../helpers/ErrorHandler";
import "react-confirm-alert/src/react-confirm-alert.css";

class ShortUrlRedirect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { short_url: null };
  }

  componentDidMount() {
    this.props.api
      .get(`short_url/get/${this.props.match.params["code"]}`)
      .then((response) => {
        if (response.data) window.location.replace(response.data.link.S);
      })
      .catch((error) => {
        ErrorHandler(error, this.props);
        this.props.updateUsername(null);
      });
  }

  render() {
    return <p></p>;
  }
}

export default ShortUrlRedirect;
