import axios from "axios";
import * as Config from "./Config";

const Api = (accessToken) => {
  const api = axios.create({
    baseURL: Config.API_URL + "/api/",
  });
  if (accessToken) api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  return api;
};

export default Api;
