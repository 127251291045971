import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Header from "./components/Header";
import Homepage from "./components/Homepage";
import NoMatch from "./components/NoMatch";
import CategoryList from "./components/QuestionAnswer/CategoryList";
import QuestionList from "./components/QuestionAnswer/QuestionList";
import QuestionSort from "./components/QuestionAnswer/QuestionSort";
import QuestionDetail from "./components/QuestionAnswer/QuestionDetail";
import QuestionEdit from "./components/QuestionAnswer/QuestionEdit";
import QuestionCreate from "./components/QuestionAnswer/QuestionCreate";
import Sudoku from "./components/Sudoku/Sudoku";
import MatchColor from "./components/MatchColor/MatchColor";
import PhotoEditor from "./components/PhotoEditor/PhotoEditor";
import ShortUrlList from "./components/ShortUrl/ShortUrlList";
import ShortUrlDetail from "./components/ShortUrl/ShortUrlDetail";
import ShortUrlCreate from "./components/ShortUrl/ShortUrlCreate";
import ShortUrlEdit from "./components/ShortUrl/ShortUrlEdit";
import ShortUrlRedirect from "./components/ShortUrl/ShortUrlRedirect";
import ActivityList from "./components/Activity/ActivityList";
import ActivityTriggerList from "./components/Activity/ActivityTriggerList";
import ActivityTriggerCreate from "./components/Activity/ActivityTriggerCreate";
import ActivityTriggerEdit from "./components/Activity/ActivityTriggerEdit";
import Api from "./helpers/Api";
import Cookies from "js-cookie";

class App extends React.Component {
  constructor(props) {
    super(props);

    const notificationMessage = { class: "info", message: "" };
    this.state = { notificationMessage: notificationMessage, api: Api(Cookies.get("access_token")), username: "" };
  }

  componentDidMount() {
    if (Cookies.get("access_token"))
      this.state.api
        .get(`user/`)
        .then((response) => {
          this.setState({ username: response.data[0].full_name.S });
        })
        .catch((error) => {
          this.setState({ username: "" });
          Cookies.remove("access_token");
        });
  }

  notificationMessageHandler = async (notificationMessage) => {
    this.setState({ notificationMessage: notificationMessage });
  };

  apiAccesstokenHandler = (accessToken, username) => {
    this.setState({ api: Api(accessToken), username: username });
  };

  updateUsername = (username) => {
    this.setState({ username: username });
  };

  render() {
    return (
      <div>
        <Router>
          <Header username={this.state.username} notificationMessage={this.state.notificationMessage} />
          <Routes>
            <Route
              path="/login"
              element={
                <Login
                  setNotificationMessage={this.notificationMessageHandler}
                  refreshAccessToken={this.apiAccesstokenHandler}
                  api={this.state.api}
                />
              }
            />
            <Route path="/" element={<Homepage username={this.state.username} />} />
            <Route
              path="/qa"
              element={
                <CategoryList
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route
              path="/qa/:categoryId/:questionId/answer"
              element={
                <QuestionDetail
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route
              path="/qa/:categoryId/:questionId/edit"
              element={
                <QuestionEdit
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route
              path="/qa/:categoryId"
              element={
                <QuestionList
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route
              path="/qa/:categoryId/sort"
              element={
                <QuestionSort
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route
              path="/qa/:categoryId/create"
              element={
                <QuestionCreate
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route
              path="/sudoku"
              element={<Sudoku setNotificationMessage={this.notificationMessageHandler} api={this.state.api} />}
            />
            <Route
              path="/match-color"
              element={<MatchColor setNotificationMessage={this.notificationMessageHandler} />}
            />
            <Route
              path="/photo-editor"
              element={<PhotoEditor setNotificationMessage={this.notificationMessageHandler} api={this.state.api} />}
            />
            <Route
              path="/short-url"
              element={
                <ShortUrlList
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route
              path="/short-url/create"
              element={
                <ShortUrlCreate
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route
              path="/short-url/:code/detail"
              element={
                <ShortUrlDetail
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route
              path="/short-url/:code/edit"
              element={
                <ShortUrlEdit
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route
              path="/short-url/:code"
              element={
                <ShortUrlRedirect
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route
              path="/activity"
              element={
                <ActivityList
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route
              path="/activity/trigger"
              element={
                <ActivityTriggerList
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route
              path="/activity/trigger/create"
              element={
                <ActivityTriggerCreate
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route
              path="/activity/trigger/:code/edit"
              element={
                <ActivityTriggerEdit
                  setNotificationMessage={this.notificationMessageHandler}
                  api={this.state.api}
                  updateUsername={this.updateUsername}
                />
              }
            />
            <Route path="*" element={<NoMatch />}></Route>
          </Routes>
        </Router>
      </div>
    );
  }
}

export default App;
