import { React, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faPlusCircle, faSort } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../../helpers/ErrorHandler";

export default function QuestionList({ setNotificationMessage, api, updateUsername }) {
  let { categoryId } = useParams();
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    api
      .get(`qa/${categoryId}/list`)
      .then((response) => {
        if (response.data) setQuestions(response.data);
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
        updateUsername(null);
      });
  }, [categoryId]);

  var key = 1;
  const renderQuestionList = questions.map((question) => {
    return (
      <div key={key} className="pb-2">
        <span className="pe-3">{key++}</span>
        <Link
          className="text-decoration-none fw-bold"
          to={{
            pathname: `/qa/${categoryId}/${question.question_url_string.S}/answer`,
          }}
        >
          {question.question.S}
        </Link>
      </div>
    );
  });

  return (
    <div className="p-3 m-1 border rounded bg-white">
      <div className="pb-3">
        <Link className="text-decoration-none bold" to={{ pathname: `/qa` }}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" />
        </Link>
      </div>
      <div className="pb-3">
        <Link className="text-decoration-none bold pe-2" to={{ pathname: `/qa/${categoryId}/create` }}>
          <FontAwesomeIcon icon={faPlusCircle} size="lg" />
        </Link>
        <Link className="text-decoration-none bold" to={{ pathname: `/qa/${categoryId}/sort` }}>
          <FontAwesomeIcon icon={faSort} size="lg" />
        </Link>
      </div>
      <h3>Questions</h3>
      <div className="my-3">{renderQuestionList}</div>
    </div>
  );
}
