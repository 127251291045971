import React from "react";
import Cookies from "js-cookie";

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = { username: "", password: "" };
  }

  login = async (e) => {
    e.preventDefault();
    if (this.state.username === "" || this.state.password === "") {
      this.props.setNotificationMessage({ class: "warning", message: "All fields are mandatory" });
      return;
    }

    const jsonReq = { username: this.state.username, password: this.state.password };
    await this.props.api
      .post(`user/login`, jsonReq)
      .then((response) => {
        Cookies.set("access_token", response.data.access_token.S);

        this.setState({ username: "", password: "" });
        this.props.setNotificationMessage({ class: "success", message: "Logged in" });
        this.props.refreshAccessToken(response.data.access_token.S, response.data.full_name.S);
        this.props.history.push("/");
      })
      .catch((error) => {
        this.props.setNotificationMessage({ class: "danger", message: "Wrong Username or Password" });
        this.setState({ password: "" });
      });
  };

  render() {
    return (
      <div className="p-5 m-1 border rounded bg-white">
        <h2 className="text-center mb-5">Login</h2>
        <form onSubmit={this.login} style={{ width: "250px" }} className="m-auto">
          <div className="mb-3">
            <label className="form-label" htmlFor="login-username">
              <b>Username</b>
            </label>
            <input
              className="form-control"
              type="text"
              id="login-username"
              onChange={(e) => this.setState({ username: e.target.value })}
              value={this.state.username}
            />
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="login-password">
              <b>Password</b>
            </label>
            <input
              className="form-control"
              type="password"
              id="login-password"
              onChange={(e) => this.setState({ password: e.target.value })}
              value={this.state.password}
              autoComplete="on"
            />
          </div>
          <div className="mb-3 text-center">
            <button className="btn btn-primary ">Login</button>
          </div>
        </form>
      </div>
    );
  }
}

export default Login;
