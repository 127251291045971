import { React, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Tiptap from "../../helpers/Tiptap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../../helpers/ErrorHandler";

export default function QuestionCreate({ setNotificationMessage, api, updateUsername }) {
  let { categoryId } = useParams();
  const navigate = useNavigate();
  let tiptapContainer = <Tiptap updateAnswer={updateAnswerState} />;
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  function updateAnswerState(answer) {
    setAnswer(answer);
  }

  function saveChanges(e) {
    e.preventDefault();
    var postData = {
      question: question,
      answer: answer,
      category: categoryId,
    };
    api
      .post(`qa/create-data`, JSON.stringify(postData), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setNotificationMessage({ class: "success", message: "Saved successfully" });
        navigate(`/qa/${categoryId}`);
      })
      .catch((error) => {
        updateUsername(null);
        ErrorHandler(error, setNotificationMessage);
      });
  }

  return (
    <div className="p-3 m-1 border rounded bg-white">
      <div className="pb-3">
        <Link className="text-decoration-none bold" to={{ pathname: `/qa/${categoryId}` }}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" />
        </Link>
      </div>
      <form onSubmit={saveChanges}>
        <div className="mb-3">
          <label htmlFor="question-edit-question" className="form-label">
            Question
          </label>
          <input
            id="question-edit-question"
            className="form-control"
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="question-edit-answer" className="form-label">
            Answer
          </label>
          {tiptapContainer}
        </div>
        <input type="submit" className="btn btn-primary" />
      </form>
    </div>
  );
}
