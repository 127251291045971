import { React, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../../helpers/ErrorHandler";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export default function QuestionDetail({ setNotificationMessage, api, updateUsername }) {
  let { categoryId, questionId } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState("");
  const [id, setId] = useState("");
  const [question, setQuestion] = useState("");
  const [questionUrlString, setQuestionUrlString] = useState("");
  const [answer, setAnswer] = useState("");

  useEffect(() => {
    api
      .get(`qa/${questionId}/answer`)
      .then((response) => {
        if (response.data) {
          setCategory(response.data.info_category_id.S);
          setId(response.data.sort_order.S);
          setQuestion(response.data.question.S);
          setQuestionUrlString(response.data.question_url_string.S);
          setAnswer(response.data.answer.S);
        }
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
        updateUsername(null);
      });
  }, [questionId]);

  function confirmDelete() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="text-center">
            <h2>Confirm Delete</h2>
            <p className="mb-4">Are you sure you want to delete this item?</p>
            <button className="btn btn-danger me-3" onClick={onClose}>
              No
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                deleteData();
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  }

  function deleteData(e) {
    api
      .delete(`qa/delete-data`, {
        data: { sort_order: id, id: category },
      })
      .then(() => {
        setNotificationMessage({ class: "success", message: "Deleted successfully" });
        navigate(`/qa/${categoryId}`);
      })
      .catch((error) => {
        updateUsername(null);
        ErrorHandler(error, setNotificationMessage);
      });
  }

  return (
    <div className="p-3 m-1 border rounded bg-white">
      <div className="mb-3">
        <Link className="text-decoration-none bold" to={{ pathname: `/qa/${categoryId}` }}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" className="mb-3" />
        </Link>
        <br />
        <Link
          className="text-decoration-none fw-bold pe-2"
          to={{ pathname: `/qa/${categoryId}/${questionUrlString}/edit` }}
        >
          <FontAwesomeIcon icon={faEdit} size="lg" />
        </Link>
        <Link className="text-decoration-none fw-bold" to="#" onClick={confirmDelete}>
          <FontAwesomeIcon icon={faTrashAlt} size="lg" />
        </Link>
      </div>
      <label className="fw-bold">Question</label>
      <p className="ps-3 pt-1">{question ?? <span className="fst-italic text-secondary">Not found</span>}</p>
      <label className="fw-bold">Answer</label>
      <p
        className="ps-3 pt-1"
        dangerouslySetInnerHTML={{
          __html: answer ?? '<span class="fst-italic text-secondary">Not found</span>',
        }}
      ></p>
    </div>
  );
}
