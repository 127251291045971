import React from "react";
import ErrorHandler from "../../helpers/ErrorHandler";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

class ActivityList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activities: [] };
  }

  componentDidMount() {
    this.updateActivities();
  }

  updateActivities() {
    this.props.api
      .get(`activity/list`)
      .then((response) => {
        if (response.data) this.setState({ activities: response.data });
      })
      .catch((error) => {
        ErrorHandler(error, this.props);
        this.props.history.push("/login");
      });
  }

  confirmDelete = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="text-center">
            <h2>Confirm Delete</h2>
            <p className="mb-4">Are you sure you want to delete this item?</p>
            <button className="btn btn-danger me-3" onClick={onClose}>
              No
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                this.deleteData(id);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  deleteData = (created_at) => {
    this.props.api
      .delete(`activity/delete`, {
        data: { created_at: created_at },
      })
      .then(() => {
        this.props.setNotificationMessage({ class: "success", message: "Deleted successfully" });
        this.updateActivities();
      })
      .catch((error) => {
        ErrorHandler(error, this.props);
      });
  };

  render() {
    const renderActivities = this.state.activities.map((activity, index) => {
      let date = new Date(parseInt(activity.created_at.S));
      return (
        <tr key={activity.created_at.S}>
          <td>{index + 1}</td>
          <td>{activity.activity.S}</td>
          <td>
            {date.toLocaleDateString("en-CA")}&nbsp;
            {date.toLocaleTimeString("en-US")}
          </td>
          <td>
            <Link
              className="text-decoration-none fw-bold ps-2"
              to="#"
              onClick={() => this.confirmDelete(activity.created_at.S)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Link>
          </td>
        </tr>
      );
    });

    let renderResult = (
      <table className="table table-striped table-hover border">
        <thead>
          <tr>
            <th>#</th>
            <th>Activity</th>
            <th>Created At</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{renderActivities}</tbody>
      </table>
    );

    return (
      <div className="p-3 m-1 border rounded bg-white">
        <div className="text-center">
          <h3>Activity</h3>
          <div className="text-end">
            <Link className="text-decoration-none fw-bold" to="/activity/trigger">
              <button className="btn btn-primary me-1">Triggers</button>
            </Link>
          </div>
        </div>
        <div className="text-center my-3">{renderResult}</div>
      </div>
    );
  }
}

export default ActivityList;
