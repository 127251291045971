import { React, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Tiptap from "../../helpers/Tiptap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../../helpers/ErrorHandler";

export default function QuestionEdit({ setNotificationMessage, api, updateUsername }) {
  let { categoryId, questionId } = useParams();
  const navigate = useNavigate();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [category, setCategory] = useState("");
  const [id, setId] = useState("");
  const [tiptapContainer, setTiptapContainer] = useState(null);

  useEffect(() => {
    if (answer) setTiptapContainer(<Tiptap content={answer} updateAnswer={updateAnswerState} />);
  }, [answer]);

  useEffect(() => {
    api
      .get(`qa/${questionId}/answer`)
      .then((response) => {
        if (response.data) {
          setQuestion(response.data.question.S);
          setAnswer(response.data.answer.S);
          setCategory(response.data.info_category_id.S);
          setId(response.data.sort_order.S);
        }
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
      });
  }, [questionId]);

  function updateAnswerState(answer) {
    setAnswer(answer);
  }

  function saveChanges(e) {
    e.preventDefault();
    var postData = {
      question: question,
      answer: answer,
      category: category,
      id: id,
    };
    api
      .post(`qa/edit-data`, JSON.stringify(postData), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setNotificationMessage({ class: "success", message: "Saved successfully" });
        navigate(`/qa/${categoryId}/${questionId}/answer`);
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
        updateUsername(null);
      });
  }

  return (
    <div className="p-3 m-1 border rounded bg-white">
      <div className="pb-3">
        <Link className="text-decoration-none bold" to={{ pathname: `/qa/${categoryId}/${questionId}/answer` }}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" />
        </Link>
      </div>
      <form onSubmit={saveChanges}>
        <div className="mb-3">
          <label htmlFor="question-edit-question" className="form-label">
            Question
          </label>
          <input
            id="question-edit-question"
            className="form-control"
            type="text"
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="question-edit-answer" className="form-label">
            Answer
          </label>
          {tiptapContainer}
        </div>
        <input type="submit" className="btn btn-primary" />
      </form>
    </div>
  );
}
