import React from "react";

class NoMatch extends React.Component {
  render() {
    return (
      <div className="mt-5">
        <p className="lead fw-bold text-center display-1">Page not found</p>
      </div>
    );
  }
}

export default NoMatch;
