import React from "react";
import { cloneDeep } from "lodash";

class MatchColor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      colors: ["blue", "red", "green", "purple", "orange"],
      rowCount: 12,
      colCount: 20,
      grid: [],
      scriptRunning: false,
    };
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidMount() {
    var gridInitial = [];
    for (var i = 1; i <= this.state.rowCount; i++) {
      gridInitial[i] = [];
      for (var j = 1; j <= this.state.colCount; j++) {
        gridInitial[i][j] = this.state.colors[Math.floor(Math.random() * this.state.colors.length)];
      }
    }
    this.setState({ grid: gridInitial });
  }

  componentDidUpdate(_, prevState) {
    if (JSON.stringify(prevState.grid) !== JSON.stringify(this.state.grid)) {
      if (!this.state.scriptRunning) {
        this.setState({ scriptRunning: true });
        this.timerId = setTimeout(() => this.fallToEmptyColumns(), 1000);
      }
    }
  }

  fallToEmptyColumns() {
    var grid = cloneDeep(this.state.grid);

    for (let x in grid) {
      for (let y in grid[x]) {
        if (parseInt(x) > 0 && grid[x][y] === "white") {
          let i = parseInt(x) - 1;
          while (i > 0 && grid[i][y] !== "white") {
            grid[i + 1][y] = grid[i][y];
            grid[i][y] = "white";
            i--;
          }
        }
      }
    }

    if (JSON.stringify(grid) !== JSON.stringify(this.state.grid)) this.setState({ grid: grid });

    this.timerId = setTimeout(() => this.fillWhites(), 1000);
  }

  fillWhites() {
    var grid = cloneDeep(this.state.grid);
    for (let x in grid) {
      for (let y in grid[x]) {
        if (grid[x][y] === "white")
          grid[x][y] = this.state.colors[Math.floor(Math.random() * this.state.colors.length)];
      }
    }

    if (JSON.stringify(grid) !== JSON.stringify(this.state.grid)) this.setState({ grid: grid });

    this.timerId = setTimeout(() => this.removeMatches(), 1000);
  }

  removeMatches() {
    var grid = cloneDeep(this.state.grid);
    let squareToRemove = [];
    for (let x in grid) {
      for (let y in grid[x]) {
        if (grid[x][y] === "white") continue;
        let color = grid[x][y];

        // check horizontal
        let sameColorCount = 1;
        while (grid[x][parseInt(y) + sameColorCount] && grid[x][parseInt(y) + sameColorCount] === color) {
          sameColorCount++;
        }
        if (sameColorCount >= 3) {
          for (let i = 0; i < sameColorCount; i++) {
            if (typeof squareToRemove[parseInt(x)] == "undefined") squareToRemove[parseInt(x)] = [];
            squareToRemove[parseInt(x)][parseInt(y) + i] = true;
          }
        }

        // check vertical
        sameColorCount = 1;
        while (grid[parseInt(x) + sameColorCount] && grid[parseInt(x) + sameColorCount][y] === color) {
          sameColorCount++;
        }
        if (sameColorCount >= 3) {
          for (let i = 0; i < sameColorCount; i++) {
            if (typeof squareToRemove[parseInt(x) + i] == "undefined") squareToRemove[parseInt(x) + i] = [];
            squareToRemove[parseInt(x) + i][parseInt(y)] = true;
          }
        }
      }
    }

    for (let x in squareToRemove) {
      for (let y in squareToRemove[x]) {
        if (x > 0 && y > 0 && grid[x][y].includes("dark")) grid[x][y] = "white";
        else if (grid[x][y]) grid[x][y] = "dark" + grid[x][y];
      }
    }

    this.setState({ scriptRunning: false });

    if (JSON.stringify(grid) !== JSON.stringify(this.state.grid)) this.setState({ grid: grid });
    else this.props.setNotificationMessage({ class: "success", message: "No more matches possible" });
  }

  render() {
    var childRow = (row, colCount) => {
      const cols = Array.from({ length: colCount }, (_, i) => i + 1);
      return (
        <div key={"row" + row}>
          <div>{cols.map((value, _) => childCol(row, value))}</div>
          <div className="clearfix"></div>
        </div>
      );
    };

    var childCol = (row, col) => {
      let color = "white";
      let opacity = 1;
      if (this.state.grid && this.state.grid[row] && this.state.grid[row][col]) color = this.state.grid[row][col];
      if (color.includes("dark")) opacity = 0.6;
      return (
        <div
          key={"col" + col}
          className="border float-start"
          style={{ width: "35px", height: "35px", backgroundColor: color, opacity: opacity }}
        >
          &nbsp;
        </div>
      );
    };

    var grid = (rowCount, colCount) => {
      const rows = Array.from({ length: rowCount }, (_, i) => i + 1);
      return (
        <div>
          <div className="text-center">
            <h3>Match Color</h3>
          </div>
          <div className="border m-auto mt-4" style={{ width: colCount * 35 + 2 }}>
            {rows.map((value, _) => childRow(value, colCount))}
            <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>
        </div>
      );
    };

    return <div className="p-3 m-1 border rounded bg-white">{grid(this.state.rowCount, this.state.colCount)}</div>;
  }
}

export default MatchColor;
