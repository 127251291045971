import { React, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../../helpers/ErrorHandler";
import Sortable from "sortablejs";

export default function QuestionSort({ setNotificationMessage, api, updateUsername }) {
  let { categoryId } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [sortable, setSortable] = useState("");

  useEffect(() => {
    api
      .get(`qa/${categoryId}/list`)
      .then((response) => {
        if (response.data) setQuestions(response.data);
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
        updateUsername(null);
      });
  }, [categoryId]);

  var sortContainer = document.getElementById("sort-list");
  useEffect(() => {
    if (sortContainer) {
      let sortableObj = new Sortable(sortContainer);
      setSortable(sortableObj);
    }
  }, [sortContainer]);

  function saveSort() {
    let sortOrder = sortable.toArray();

    var postData = {
      sortorder: sortOrder,
    };
    api
      .post(`/qa/save-sort`, JSON.stringify(postData), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        setNotificationMessage({ class: "success", message: "Saved successfully" });
        navigate(`/qa/${categoryId}`);
      })
      .catch((error) => {
        ErrorHandler(error, setNotificationMessage);
        updateUsername(null);
      });
  }

  return (
    <div className="p-3 m-1 border rounded bg-white">
      <div className="pb-3">
        <Link className="text-decoration-none bold" to={{ pathname: `/qa/${categoryId}` }}>
          <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" />
        </Link>
      </div>
      <h3>Sort</h3>
      <div className="my-3">
        <ul id="sort-list">
          {questions.map((question) => (
            <li data-id={question.sort_order.S} key={question.sort_order.S}>
              {question.question.S}
            </li>
          ))}
        </ul>
      </div>
      <button
        className="btn btn-primary"
        onClick={() => {
          saveSort();
        }}
      >
        Save
      </button>
    </div>
  );
}
