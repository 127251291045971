import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../../helpers/ErrorHandler";

class ShortUrlEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = { id: null, label: null, link: null };
  }

  componentDidMount() {
    const code = this.props.match.params["code"];
    this.props.api
      .get(`short_url/get/${code}`)
      .then((response) => {
        if (response.data)
          this.setState({
            code: response.data.code.S,
            label: response.data.label.S,
            link: response.data.link.S,
          });
      })
      .catch((error) => {
        ErrorHandler(error, this.props);
        this.props.updateUsername(null);
      });
  }

  saveChanges = (e) => {
    e.preventDefault();
    var postData = {
      code: this.state.code,
      label: this.state.label,
      link: this.state.link,
    };
    this.props.api
      .post(`/short_url/update`, JSON.stringify(postData), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        this.props.setNotificationMessage({ class: "success", message: "Saved successfully" });
        this.props.history.push(`/short-url/${this.props.match.params["code"]}/detail`);
      })
      .catch((error) => {
        ErrorHandler(error, this.props);
        this.props.updateUsername(null);
      });
  };

  render() {
    return (
      <div className="p-3 m-1 border rounded bg-white">
        <div className="pb-3">
          <Link
            className="text-decoration-none bold"
            to={{ pathname: `/short-url/${this.props.match.params["code"]}/detail` }}
          >
            <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" />
          </Link>
        </div>
        <form onSubmit={this.saveChanges}>
          <div className="mb-3">
            <label htmlFor="short-url-edit-label" className="form-label">
              Label
            </label>
            <input
              id="short-url-edit-label"
              className="form-control"
              type="text"
              value={this.state.label}
              onChange={(e) => this.setState({ label: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="short-url-edit-link" className="form-label">
              Link
            </label>
            <input
              id="short-url-edit-link"
              className="form-control"
              type="text"
              value={this.state.link}
              onChange={(e) => this.setState({ link: e.target.value })}
            />
          </div>
          <input type="submit" className="btn btn-primary" />
        </form>
      </div>
    );
  }
}

export default ShortUrlEdit;
