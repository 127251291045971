import React from "react";
import $ from "jquery";
import { Link } from "react-router-dom";

class Header extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.notificationMessage.message) $("#notificationContainer").show().delay(3000).fadeOut(300);
  }

  componentDidMount() {
    $("#notificationContainer").hide();
  }

  render() {
    let loginContainer;
    if (this.props.username) {
      loginContainer = (
        <p className="text-end pt-4">
          Welcome, <b className="fs-4">{this.props.username}</b>
        </p>
      );
    } else {
      loginContainer = (
        <p className="text-end pt-4 lead">
          <Link className="text-light text-end pt-4" to="/login">
            Login
          </Link>
        </p>
      );
    }

    return (
      <div>
        <div className="border rounded m-1 bg-primary text-light row">
          <Link className="col text-light text-decoration-none" to="/">
            <h1 className="fw-bold p-3 col">DPKhere</h1>
          </Link>
          <div className="col">{loginContainer}</div>
        </div>
        <p
          id="notificationContainer"
          className={`p-2 mx-5 my-2 bg-${this.props.notificationMessage.class} bg-gradient text-center rounded`}
        >
          {this.props.notificationMessage.message}
        </p>
      </div>
    );
  }
}

export default Header;
