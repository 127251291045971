import React from "react";
import ErrorHandler from "../../helpers/ErrorHandler";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlusCircle, faEdit, faArrowAltCircleLeft, faCopy } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import * as Config from "../../helpers/Config";

class ActivityTriggerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activityTriggers: [] };
  }

  componentDidMount() {
    this.updateActivityTriggers();
  }

  updateActivityTriggers() {
    this.props.api
      .get(`activity/trigger/list`)
      .then((response) => {
        if (response.data) this.setState({ activityTriggers: response.data });
      })
      .catch((error) => {
        ErrorHandler(error, this.props);
        this.props.history.push("/login");
      });
  }

  confirmDelete = (code) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="text-center">
            <h2>Confirm Delete</h2>
            <p className="mb-4">Are you sure you want to delete this item?</p>
            <button className="btn btn-danger me-3" onClick={onClose}>
              No
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                this.deleteData(code);
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  deleteData = (code) => {
    this.props.api
      .delete(`activity/trigger/delete`, {
        data: { code: code },
      })
      .then(() => {
        this.props.setNotificationMessage({ class: "success", message: "Deleted successfully" });
        this.updateActivityTriggers();
      })
      .catch((error) => {
        ErrorHandler(error, this.props);
      });
  };

  render() {
    const renderActivityTriggers = this.state.activityTriggers.map((activityTrigger, index) => {
      return (
        <tr key={activityTrigger.code.S}>
          <td>{index + 1}</td>
          <td>{activityTrigger.trigger_name.S}</td>
          <td>{activityTrigger.code.S}</td>
          <td>
            <Link
              className="ps-2 float-left"
              to="/#"
              onClick={(e) => {
                navigator.clipboard.writeText(Config.API_URL + `/api/activity/trigger/${activityTrigger.code.S}`);
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </Link>
            <Link
              className="text-decoration-none fw-bold ps-2"
              to={{ pathname: `/activity/trigger/${activityTrigger.code.S}/edit` }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <Link
              className="text-decoration-none fw-bold ps-2"
              to="#"
              onClick={() => this.confirmDelete(activityTrigger.code.S)}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Link>
          </td>
        </tr>
      );
    });

    let renderResult = (
      <table className="table table-striped table-hover border">
        <thead>
          <tr>
            <th>#</th>
            <th>Trigger Name</th>
            <th>Code</th>
            <th></th>
          </tr>
        </thead>
        <tbody>{renderActivityTriggers}</tbody>
      </table>
    );

    return (
      <div className="p-3 m-1 border rounded bg-white">
        <div className="pb-3">
          <Link className="text-decoration-none" to={{ pathname: `/activity` }}>
            <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" className="mb-3" />
          </Link>
          <br />
          <Link className="text-decoration-none" to={{ pathname: `/activity/trigger/create` }}>
            <FontAwesomeIcon icon={faPlusCircle} size="lg" />
          </Link>
        </div>
        <div className="text-center">
          <h3>Activity Triggers</h3>
        </div>
        <div className="text-center my-3">{renderResult}</div>
      </div>
    );
  }
}

export default ActivityTriggerList;
