import React from "react";
import deepcopy from "deepcopy";
import { format } from "date-fns";
import ErrorHandler from "../../helpers/ErrorHandler";

class Sudoku extends React.Component {
  constructor(props) {
    let sudokuErrors = [];
    for (let i = 1; i <= 9; i++) {
      sudokuErrors[i] = [];
      for (let j = 1; j <= 9; j++) sudokuErrors[i][j] = "none";
    }

    super(props);
    this.state = {
      sudoku: [],
      sudokuErrors: sudokuErrors,
      originalSudoku: [],
      date: new Date(),
    };
  }

  componentDidMount() {
    this.timerId = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  clearSudoku = () => {
    this.setState({
      sudoku: Array(9).fill(Array(9).fill(0)),
    });
  };

  tick() {
    this.setState({
      date: new Date(),
    });
  }

  loadSudoku = (level) => {
    this.resetColors();

    const data_easy = [
      [0, 0, 7, 3, 4, 2, 0, 6, 0],
      [0, 5, 0, 6, 0, 0, 0, 0, 3],
      [0, 0, 3, 0, 0, 0, 0, 0, 7],
      [0, 0, 9, 7, 0, 4, 6, 0, 0],
      [0, 3, 8, 1, 0, 0, 4, 0, 0],
      [0, 6, 4, 5, 0, 8, 1, 0, 2],
      [9, 0, 6, 0, 0, 0, 0, 5, 1],
      [0, 0, 5, 8, 9, 0, 7, 2, 0],
      [0, 7, 0, 2, 0, 6, 3, 0, 9],
    ];
    const data_medium = [
      [0, 0, 8, 0, 0, 1, 5, 4, 0],
      [0, 5, 0, 2, 0, 0, 3, 0, 0],
      [0, 0, 0, 7, 3, 0, 2, 0, 1],
      [4, 0, 0, 0, 5, 2, 3, 1, 0],
      [0, 7, 0, 0, 0, 1, 5, 0, 0],
      [0, 0, 0, 0, 9, 0, 0, 0, 2],
      [0, 0, 3, 9, 2, 0, 0, 0, 5],
      [0, 0, 0, 0, 6, 3, 0, 0, 8],
      [0, 0, 6, 1, 0, 0, 0, 0, 0],
    ];
    const data_hard = [
      [0, 0, 1, 0, 0, 3, 0, 0, 2],
      [0, 0, 0, 2, 1, 0, 0, 7, 0],
      [0, 0, 6, 5, 0, 0, 0, 0, 8],
      [0, 9, 0, 0, 7, 0, 0, 0, 0],
      [0, 5, 0, 0, 0, 4, 6, 0, 1],
      [0, 0, 4, 6, 0, 0, 0, 0, 0],
      [0, 0, 7, 0, 0, 0, 0, 8, 0],
      [0, 4, 0, 0, 0, 0, 0, 2, 0],
      [0, 3, 0, 0, 0, 0, 0, 0, 5],
    ];
    const data_expert = [
      [8, 0, 0, 0, 0, 4, 0, 0, 9],
      [0, 1, 0, 0, 5, 0, 0, 0, 4],
      [0, 7, 0, 0, 0, 2, 0, 3, 0],
      [0, 5, 0, 0, 0, 0, 9, 0, 0],
      [2, 0, 0, 0, 0, 0, 0, 6, 3],
      [0, 0, 0, 6, 8, 0, 0, 0, 0],
      [0, 0, 0, 7, 0, 0, 0, 8, 0],
      [0, 0, 0, 5, 2, 0, 0, 0, 0],
      [3, 4, 0, 0, 0, 1, 0, 0, 0],
    ];

    switch (level) {
      case "EASY":
        this.setState({ originalSudoku: deepcopy(data_easy), sudoku: deepcopy(data_easy) });
        break;
      case "MEDIUM":
        this.setState({ originalSudoku: deepcopy(data_medium), sudoku: deepcopy(data_medium) });
        break;
      case "HARD":
        this.setState({ originalSudoku: deepcopy(data_hard), sudoku: deepcopy(data_hard) });
        break;
      case "EXPERT":
        this.setState({ originalSudoku: deepcopy(data_expert), sudoku: deepcopy(data_expert) });
        break;
      case "GENERATE":
        try {
          this.props.api
            .get(`sudoku/create`)
            .then((response) => {
              if (response.data)
                this.setState({ originalSudoku: deepcopy(response.data), sudoku: deepcopy(response.data) });
            })
            .catch((error) => {
              ErrorHandler(error, this.props);
            });
        } catch (err) {
          console.error(err);
        }
        break;
      default:
        break;
    }
  };

  solveSudoku = () => {
    if (!this.state.sudoku || this.state.sudoku.length === 0) return;

    var possible = [];
    for (let i = 0; i < 9; i++) {
      possible[i] = [];
      for (let j = 0; j < 9; j++) {
        possible[i][j] = [this.state.sudoku[i][j]];
        if (this.state.sudoku[i][j] === 0) possible[i][j] = [1, 2, 3, 4, 5, 6, 7, 8, 9];
      }
    }

    let solvedSudoku = this.state.sudoku;
    do {
      var possibletemp = possible.toString();
      var datatemp = this.state.sudoku.toString();
      for (let i = 0; i < 9; i++) {
        for (let j = 0; j < 9; j++) {
          // remove by same square
          for (let y = 0; y < 9; y++) {
            if (j !== y) {
              let index = possible[i][y].indexOf(solvedSudoku[i][j]);
              if (index !== -1) {
                possible[i][y].splice(index, 1);
                if (possible[i][y].length === 1) solvedSudoku[i][y] = possible[i][y][Object.keys(possible[i][y])[0]];
              }
            }
          }

          // remove by same row
          let x = i + 1;
          if (Math.floor(x / 3) !== Math.floor(i / 3)) x -= 3;
          while (x !== i) {
            let y = j;
            do {
              let index = possible[i][j].indexOf(solvedSudoku[x][y]);
              if (index !== -1) {
                possible[i][j].splice(index, 1);
                if (possible[i][j].length === 1) solvedSudoku[i][j] = possible[i][j][Object.keys(possible[i][j])[0]];
              }
              y += 1;
              if (Math.floor(y / 3) !== Math.floor(j / 3)) y -= 3;
            } while (y !== j);
            x += 1;
            if (Math.floor(x / 3) !== Math.floor(i / 3)) x -= 3;
          }

          // remove from same column
          x = i + 3;
          if (x > 8) x -= 9;
          while (x !== i) {
            let y = j;
            do {
              let index = possible[i][j].indexOf(solvedSudoku[x][y]);
              if (index !== -1) {
                possible[i][j].splice(index, 1);
                if (possible[i][j].length === 1) solvedSudoku[i][j] = possible[i][j][Object.keys(possible[i][j])[0]];
              }
              y += 3;
              if (y > 8) y -= 9;
            } while (y !== j);
            x += 3;
            if (x > 8) x -= 9;
          }

          // possible only in current col by square
          if (possible[i][j].length > 1) {
            possible[i][j].forEach((possibleValue, key) => {
              var possibleFlag = true;
              var possibleOnlyHorLine = true;
              var possibleOnlyVerLine = true;
              for (let y = 0; y < 9; y++) {
                let index = possible[i][y].indexOf(possibleValue);
                if (index !== -1 && y !== j) {
                  possibleFlag = false;
                  if (y % 3 !== j % 3) possibleOnlyVerLine = false;
                  if (Math.floor(y / 3) !== Math.floor(j / 3)) possibleOnlyHorLine = false;
                }
              }
              if (possibleFlag) {
                solvedSudoku[i][j] = possibleValue;
                possible[i][j] = [possibleValue];
              }
              // remove possible from vertical line other squares
              if (possibleOnlyVerLine) {
                let x = i + 3;
                if (x > 8) x -= 9;
                do {
                  let y = j;
                  do {
                    let index = possible[x][y].indexOf(possibleValue);
                    if (index !== -1) {
                      possible[x][y].splice(index, 1);
                      if (possible[x][y].length === 1)
                        solvedSudoku[x][y] = possible[x][y][Object.keys(possible[x][y])[0]];
                    }
                    y += 3;
                    if (y > 8) y -= 9;
                  } while (y !== j);
                  x += 3;
                  if (x > 8) x -= 9;
                } while (x !== i);
              }
              // remove possible from horrizontal line other squares
              if (possibleOnlyHorLine) {
                let x = i + 1;
                if (Math.floor(x / 3) !== Math.floor(i / 3)) x -= 3;
                do {
                  let y = j;
                  do {
                    let index = possible[x][y].indexOf(possibleValue);
                    if (index !== -1) {
                      possible[x][y].splice(index, 1);
                      if (possible[x][y].length === 1)
                        solvedSudoku[x][y] = possible[x][y][Object.keys(possible[x][y])[0]];
                    }
                    y++;
                    if (Math.floor(y / 3) !== Math.floor(j / 3)) y -= 3;
                  } while (y !== j);
                  x++;
                  if (Math.floor(x / 3) !== Math.floor(i / 3)) x -= 3;
                } while (x !== i);
              }
            });
          }

          // possible only in current col by horizontal line
          if (possible[i][j].length > 1) {
            possible[i][j].forEach((possibleValue, key) => {
              var possibleFlag = true;
              let x = i;
              do {
                let y = j;
                do {
                  var index = possible[x][y].indexOf(possibleValue);
                  if (index !== -1 && (y !== j || x !== i)) possibleFlag = false;
                  y++;
                  if (Math.floor(y / 3) !== Math.floor(j / 3)) y -= 3;
                } while (y !== j);
                x++;
                if (Math.floor(x / 3) !== Math.floor(i / 3)) x -= 3;
              } while (x !== i);
              if (possibleFlag) {
                solvedSudoku[i][j] = possibleValue;
                possible[i][j] = [possibleValue];
              }
            });
          }

          // possible only in current col by vertical line
          if (possible[i][j].length > 1) {
            possible[i][j].forEach((possibleValue, key) => {
              var possibleFlag = true;
              let x = i;
              do {
                let y = j;
                do {
                  var index = possible[x][y].indexOf(possibleValue);
                  if (index !== -1 && (y !== j || x !== i)) possibleFlag = false;
                  y += 3;
                  if (y > 8) y -= 9;
                } while (y !== j);
                x += 3;
                if (x > 8) x -= 9;
              } while (x !== i);
              if (possibleFlag) {
                solvedSudoku[i][j] = possibleValue;
                possible[i][j] = [possibleValue];
              }
            });
          }
        }
      }
    } while (possible.toString() !== possibletemp || solvedSudoku.toString() !== datatemp);

    this.setState({ sudoku: solvedSudoku });

    this.validateSudoku();
  };

  resetColors() {
    let sudokuErrors = [];
    for (let i = 1; i <= 9; i++) {
      sudokuErrors[i] = [];
      for (let j = 1; j <= 9; j++) sudokuErrors[i][j] = "none";
    }

    this.setState({ sudokuErrors: sudokuErrors });
  }

  validateSudoku() {
    let errorFlag = false;
    let isSolved = true;
    let sudokuErrors = [];
    for (let i = 1; i <= 9; i++) {
      sudokuErrors[i] = [];
      for (let j = 1; j <= 9; j++) sudokuErrors[i][j] = "none";
    }
    let sudoku = this.state.sudoku;
    for (let value = 1; value <= 9; value++) {
      for (let i = 1; i <= 9; i++) {
        let duplicates = [];
        for (let j = 1; j <= 9; j++) {
          if (sudoku[i - 1][j - 1] === 0) isSolved = false;
          if (sudoku[i - 1][j - 1] === value) duplicates.push([i, j]);
        }

        if (duplicates.length > 1) {
          errorFlag = true;
          duplicates.forEach((duplicate) => {
            sudokuErrors[duplicate[0]][duplicate[1]] = "error";
          });
        }
      }
    }

    if (errorFlag) this.props.setNotificationMessage({ class: "danger", message: "Wrong value entered" });
    else if (isSolved) this.props.setNotificationMessage({ class: "success", message: "Solved" });

    this.setState({ sudokuErrors: sudokuErrors });
  }

  updateSudoku(outercolId, innerColId, target) {
    if (target.value.trim() && !isNaN(target.value.trim())) {
      let sudoku = this.state.sudoku;
      sudoku[outercolId - 1][innerColId - 1] = parseInt(target.value);
      this.setState({ sudoku: sudoku });
    } else {
      target.value = null;
      this.props.setNotificationMessage({ class: "danger", message: "Only numbers allowed" });
    }

    this.validateSudoku();
  }

  resetCol(outercolId, innerColId) {
    if (this.state.originalSudoku[outercolId - 1][innerColId - 1] === 0) {
      let sudoku = this.state.sudoku;
      sudoku[outercolId - 1][innerColId - 1] = 0;
      this.setState({ sudoku: sudoku });
      this.validateSudoku();
    }
  }

  render() {
    const oneToNine = Array.from({ length: 9 }, (_, i) => i + 1);

    var innerColContent = (outercolId, innerColId) => {
      if (
        this.state.sudoku &&
        this.state.sudoku[outercolId - 1] &&
        this.state.sudoku[outercolId - 1][innerColId - 1] === 0
      ) {
        return (
          <input
            style={{ width: "40px", height: "40px" }}
            className="border-0 text-center fs-3"
            maxLength={1}
            onChange={(e) => this.updateSudoku(outercolId, innerColId, e.target)}
          />
        );
      } else {
        var textColor = "text-primary fw-bold";
        if (
          this.state.sudoku &&
          this.state.sudoku[outercolId - 1] &&
          this.state.originalSudoku &&
          this.state.originalSudoku[outercolId - 1] &&
          this.state.sudoku[outercolId - 1][innerColId - 1] !==
            this.state.originalSudoku[outercolId - 1][innerColId - 1]
        )
          textColor = "text-success ";

        if (this.state.sudokuErrors[outercolId][innerColId] !== "none") textColor = "text-danger ";

        return (
          <div className={"text-center fs-3 " + textColor} style={{ height: "40px" }}>
            {this.state.sudoku &&
            this.state.sudoku[outercolId - 1] &&
            this.state.sudoku[outercolId - 1][innerColId - 1] !== 0
              ? this.state.sudoku[outercolId - 1][innerColId - 1]
              : ""}
          </div>
        );
      }
    };

    var innerCol = (outercolId, innerColId) => {
      return (
        <div
          className="border float-start"
          key={"innerCol" + innerColId}
          style={{ width: "45px" }}
          onDoubleClick={(e) => this.resetCol(outercolId, innerColId)}
        >
          {innerColContent(outercolId, innerColId)}
        </div>
      );
    };

    var outerCol = (outerColId) => {
      return (
        <div className="border float-start" key={"outerCol" + outerColId} style={{ width: "137px" }}>
          {oneToNine.map((value, _) => innerCol(outerColId, value))}
          <div className="clearfix"></div>
        </div>
      );
    };

    var sudokuGrid = () => {
      return (
        <div>
          <div className="float-start text-center border-end mt-5 pe-3 me-3" style={{ width: "30%" }}>
            <div className="mb-3">{format(this.state.date, "yyyy-MM-dd hh:mm:ss a")}</div>
            <h3>Load Sudoku</h3>
            <div>
              <button type="button" className="btn btn-primary mt-4" onClick={() => this.loadSudoku("EASY")}>
                EASY
              </button>
              <br />
              <button type="button" className="btn btn-primary mt-2" onClick={() => this.loadSudoku("MEDIUM")}>
                MEDIUM
              </button>
              <br />
              <button type="button" className="btn btn-primary mt-2" onClick={() => this.loadSudoku("HARD")}>
                HARD
              </button>
              <br />
              <button type="button" className="btn btn-primary mt-2" onClick={() => this.loadSudoku("EXPERT")}>
                EXPERT
              </button>
              <br />
              <button type="button" className="btn btn-primary mt-2" onClick={() => this.loadSudoku("GENERATE")}>
                GENERATE
              </button>
            </div>
          </div>
          <div className="float-start" style={{ width: "65%" }}>
            <div className="border m-auto" style={{ width: "413px" }}>
              {oneToNine.map((value, _) => outerCol(value))}
              <div className="clearfix"></div>
            </div>
            <div className="text-center mt-3">
              <button type="button" className="btn btn-light me-4" onClick={this.clearSudoku}>
                Clear
              </button>
              <button type="button" className="btn btn-success" onClick={this.solveSudoku}>
                Solve
              </button>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      );
    };

    return <div className="p-3 m-1 border rounded bg-white">{sudokuGrid()}</div>;
  }
}

export default Sudoku;
