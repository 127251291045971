import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../../helpers/ErrorHandler";

class ActivityTriggerCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { id: null, triggerName: "" };
  }

  saveChanges = (e) => {
    e.preventDefault();
    var postData = {
      trigger_name: this.state.triggerName,
    };
    this.props.api
      .post(`/activity/trigger/create`, JSON.stringify(postData), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        this.props.setNotificationMessage({ class: "success", message: "Saved successfully" });
        this.props.history.push(`/activity/trigger`);
      })
      .catch((error) => {
        ErrorHandler(error, this.props);
        this.props.updateUsername(null);
      });
  };

  render() {
    return (
      <div className="p-3 m-1 border rounded bg-white">
        <div className="pb-3">
          <Link className="text-decoration-none bold" to={{ pathname: `/activity/trigger` }}>
            <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" />
          </Link>
        </div>
        <form onSubmit={this.saveChanges}>
          <div className="mb-3">
            <label htmlFor="trigger-edit-name" className="form-label">
              Trigger Name
            </label>
            <input
              id="trigger-edit-name"
              className="form-control"
              type="text"
              value={this.state.triggerName}
              onChange={(e) => this.setState({ triggerName: e.target.value })}
            />
          </div>
          <input type="submit" className="btn btn-primary" />
        </form>
      </div>
    );
  }
}

export default ActivityTriggerCreate;
