import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ErrorHandler from "../../helpers/ErrorHandler";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

class ShortUrlDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { short_url: null };
  }

  componentDidMount() {
    this.props.api
      .get(`short_url/get/${this.props.match.params["code"]}`)
      .then((response) => {
        if (response.data)
          this.setState({
            short_url: response.data,
          });
      })
      .catch((error) => {
        ErrorHandler(error, this.props);
        this.props.updateUsername(null);
      });
  }

  confirmDelete = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="text-center">
            <h2>Confirm Delete</h2>
            <p className="mb-4">Are you sure you want to delete this item?</p>
            <button className="btn btn-danger me-3" onClick={onClose}>
              No
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                this.deleteData();
                onClose();
              }}
            >
              Yes, Delete it!
            </button>
          </div>
        );
      },
    });
  };

  deleteData = (e) => {
    this.props.api
      .delete(`short_url/delete`, {
        data: { code: this.props.match.params["code"] },
      })
      .then(() => {
        this.props.setNotificationMessage({ class: "success", message: "Deleted successfully" });
        this.props.history.push(`/short-url`);
      })
      .catch((error) => {
        ErrorHandler(error, this.props);
        this.props.updateUsername(null);
      });
  };

  render() {
    return (
      <div className="p-3 m-1 border rounded bg-white">
        <div className="mb-3">
          <Link className="text-decoration-none bold" to={{ pathname: `/short-url` }}>
            <FontAwesomeIcon icon={faArrowAltCircleLeft} size="lg" className="mb-3" />
          </Link>
          <br />
          <Link
            className="text-decoration-none fw-bold pe-2"
            to={{ pathname: `/short-url/${this.props.match.params["code"]}/edit` }}
          >
            <FontAwesomeIcon icon={faEdit} size="lg" />
          </Link>
          <Link className="text-decoration-none fw-bold" to="#" onClick={this.confirmDelete}>
            <FontAwesomeIcon icon={faTrashAlt} size="lg" />
          </Link>
        </div>
        <label className="fw-bold">Label</label>
        <p className="ps-3 pt-1">
          {this.state.short_url ? (
            this.state.short_url.label.S
          ) : (
            <span className="fst-italic text-secondary">Not found</span>
          )}
        </p>
        <label className="fw-bold">Code</label>
        <p className="ps-3 pt-1">
          {this.state.short_url ? (
            this.state.short_url.code.S
          ) : (
            <span className="fst-italic text-secondary">Not found</span>
          )}
        </p>
        <label className="fw-bold">Link</label>
        <p className="ps-3 pt-1">
          {this.state.short_url ? (
            this.state.short_url.link.S
          ) : (
            <span className="fst-italic text-secondary">Not found</span>
          )}
        </p>
      </div>
    );
  }
}

export default ShortUrlDetail;
